<template>
    <div class="home-new">
        <h1 class="home-new__heading-h1">{{ titleH1 }}</h1>

        <section
            class="grid-section"
            v-if="pageData.page.data.featured_stores && pageData.page.data.featured_stores.length"
        >
            <ul class="grid-section__list">
                <li
                    class="grid-section__list-item"
                    v-for="(item, index) in pageData.page.data.featured_stores"
                    :key="index"
                >
                    <CardNewHomepageGridCard :info="item" />
                </li>
            </ul>
        </section>

        <section
            class="trending-section"
            v-if="pageData.page.data.frequent_searches && pageData.page.data.frequent_searches.length"
        >
            <article class="trending-searches">
                <ul class="trending-searches__list">
                    <li class="trending-searches__item">
                        <span class="trending-searches__title">Busquedas más frecuentes:</span>
                    </li>
                    <li v-for="item in pageData.page.data.frequent_searches" class="trending-searches__item">
                        <NuxtLink
                            :to="`/${!$isCupohosting ? `${$lang.routes.brands}/` : ''}${item.slug}`"
                            class="trending-searches__item-link"
                        >
                            {{ item.name }}
                        </NuxtLink>
                    </li>
                </ul>
            </article>
        </section>

        <section
            class="banner-section"
            v-if="featuredBanner"
            :style="{ backgroundImage: `url(${featuredBanner.image.url})` }"
        >
            <span class="banner-section__title">{{ featuredBanner.title }}</span>
            <span class="banner-section__subtitle">{{ featuredBanner.description }}</span>

            <NuxtLink
                :to="featuredBanner.url"
                class="banner-section__link"
                :style="`background-color: ${featuredBanner.btn_color};`"
            >
                {{ featuredBanner.text_button }}
            </NuxtLink>
        </section>

        <section class="coupons-trending">
            <h2 class="home-new__heading-h2">Cupones de Tendencia de Hoy</h2>

            <ul class="coupons-trending__list">
                <template v-for="(item, key) in pageData.page.data.popular_discounts" :key="key">
                    <li v-if="item.is_admin" :style="{ order: key + 1 }">
                        <DelayHydration>
                            <DCardAdmin :info="item" />
                        </DelayHydration>
                    </li>
                </template>
            </ul>
        </section>

        <section
            class="popular-stores"
            v-if="pageData.page.data.popular_stores && pageData.page.data.popular_stores.length"
        >
            <h2 class="home-new__heading-h2">Tiendas populares</h2>
            <MiscStoreGallery :stores="pageData.page.data.popular_stores" />
        </section>

        <section class="send-coupon-faq" v-if="pageData.page.faqs && pageData.page.faqs.length">
            <h2 class="home-new__heading-h2">FAQ:</h2>
            <article>
                <details
                    class="send-coupon-faq__details"
                    ref="detailsRef"
                    v-for="(item, index) in pageData.page.faqs"
                    :key="index"
                >
                    <summary class="send-coupon-faq__summary">
                        {{ item.question }}

                        <img
                            :src="$assets.gray.arrowDown"
                            class="send-coupon-faq__summary-icon"
                            alt="flecha abajo"
                        />
                    </summary>
                    <p class="send-coupon-faq__answer">{{ item.answer }}</p>
                </details>
            </article>
        </section>

        <section
            class="trending-section"
            v-if="pageData.page.data.popular_articles && pageData.page.data.popular_articles.length"
        >
            <article class="trending-searches">
                <ul class="trending-searches__list">
                    <li class="trending-searches__item">
                        <span class="trending-searches__title">Artículos populares:</span>
                    </li>
                    <li v-for="item in pageData.page.data.popular_articles" class="trending-searches__item">
                        <NuxtLink :to="item.url" class="trending-searches__item-link" rel="follow">
                            {{ item.title }}
                        </NuxtLink>
                    </li>
                </ul>
            </article>
        </section>
    </div>
</template>

<script lang="ts" setup>
import { useRootStore } from '~/store/root'

import { replacePlaceholders } from '~/util/textFunction'
import type { Api } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'

const {
    public: {
        variant: {
            value: variantValue,
            metaData: { siteOrigin, siteName },
        },
    },
} = useRuntimeConfig()

const { currentDate } = useDateFunctions()

const currentDates = currentDate()
const { $lang } = useNuxtApp()
const Route = useRoute()
const RootStore = useRootStore()

const detailsRef = ref<HTMLDetailsElement[]>([])

const { buildHeaders, baseUrl, endpoints } = useApiClient()

const headers = buildHeaders()

const { data: homepageData, error } = await useAsyncData('homepage-data', async () => {
    try {
        const pageData = await $fetch<Api.Responses.Pages.Home>(endpoints.pages.home.path, {
            method: 'GET',
            headers,
            baseURL: baseUrl,
        })

        return {
            pageData,
        }
    } catch (error) {
        throw createError({
            statusCode: 500,
            message: 'Algo salió mal',
        })
    }
})

if (!homepageData.value) throw new Error('Homepage data not found')

const { pageData } = homepageData.value

const featuredBanner = pageData.page.featuredBanner

const [title, description, titleH1] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [pageData.page.title || '', pageData.page.description || '', pageData.page.h1 || ''],
)

useSeoMeta({
    title: title,
    ogUrl: siteOrigin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
    ...(Route.path !== '/' ? { robots: 'noindex' } : {}),
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: siteOrigin + Route.path,
        },
    ],
})

useJsonld({
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'WebSite' as 'WebSite',
            name: $lang.brand,
            url: siteOrigin,
        },
        {
            '@type': 'Organization' as 'Organization',
            name: $lang.brand,
            url: siteOrigin,
            brand: $lang.brand,
            logo: `${siteOrigin}/assets/schema.org/organisation/${variantValue}.png`,
            email: $lang.views.homepage.schemeOrg.email,
            founder: 'Juan Hurtado',
            sameAs: $lang.views.homepage.schemeOrg.sameAs,
            address: $lang.views.homepage.schemeOrg.address,
        },
    ],
})

onMounted(() => {
    detailsRef.value.forEach((item) => {
        item.addEventListener('click', () => {
            detailsRef.value.forEach((detail) => {
                if (detail !== item) {
                    detail.removeAttribute('open')
                }
            })
        })
    })
})
</script>

<style lang="postcss">
.home-new {
    @apply container relative mx-auto space-y-6 pb-14 pt-5 lg:max-w-5xl lg:space-y-8;

    &__heading-h1 {
        @apply text-center text-[28px] md:text-[32px];
    }
    &__heading-h2 {
        @apply text-center text-2xl lg:text-[28px];
    }

    & .grid-section {
        &__list {
            @apply m-[0_-5px_25px] flex flex-wrap justify-center;

            &-item {
                @apply mb-[5px] min-w-[150px] max-w-[166px] flex-1 p-[5px_5px_0] md:max-w-[188px];
            }
        }
    }

    & .trending-section {
        @apply relative flex flex-wrap justify-center rounded-[5px] p-[15px_25px_0];
        background-image: repeating-linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2) 6px,
                transparent 0,
                transparent 10px,
                rgba(0, 0, 0, 0.2) 0
            ),
            repeating-linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2) 6px,
                transparent 0,
                transparent 10px,
                rgba(0, 0, 0, 0.2) 0
            ),
            repeating-linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2) 6px,
                transparent 0,
                transparent 10px,
                rgba(0, 0, 0, 0.2) 0
            ),
            repeating-linear-gradient(
                270deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2) 6px,
                transparent 0,
                transparent 10px,
                rgba(0, 0, 0, 0.2) 0
            );
        background-position:
            0 0,
            0 0,
            100% 0,
            0 100%;
        background-repeat: no-repeat;
        background-size:
            1px 100%,
            100% 1px,
            1px 100%,
            100% 1px;

        & .trending-searches {
            @apply flex flex-col justify-center md:flex-row;

            &__title {
                @apply text-nowrap text-center text-base font-bold;
            }

            &__list {
                @apply flex flex-wrap justify-center;
            }

            &__item {
                @apply mb-[15px] mr-[15px];

                &-link {
                    @apply text-nowrap text-base font-medium text-site-gray underline;
                }
            }
        }
    }

    & .banner-section {
        @apply flex h-[292px] flex-col items-center justify-around bg-no-repeat px-[45px] py-[25px];

        &__title {
            @apply text-[36px] font-bold text-white;
        }

        &__subtitle {
            @apply text-[22x] text-white;
        }

        &__link {
            @apply w-fit cursor-pointer whitespace-nowrap rounded-[3px] border border-white px-10 py-[15px] text-[13px] font-bold text-white;
        }
    }

    & .coupons-trending {
        @apply space-y-6 lg:space-y-8;
        &__list {
            @apply flex flex-col gap-y-3;
        }
    }

    & .popular-stores {
        @apply space-y-6 lg:space-y-8;
    }

    & .send-coupon-faq {
        @apply space-y-6 lg:space-y-8;

        &__details {
            @apply mb-[5px] rounded-xl bg-white;
        }

        &__summary {
            @apply flex cursor-pointer items-center gap-x-[15px] rounded px-[35px] py-[25px] text-base font-semibold md:px-[45px] md:py-[35px] md:text-[22px];

            &-icon {
                @apply ml-auto size-5;
            }
        }

        &__answer {
            @apply rounded-b px-[25px] pb-[15px] text-sm text-black md:text-base;
        }
    }
}

.send-coupon-faq__details[open] .send-coupon-faq__summary-icon {
    @apply rotate-180;
}
</style>
